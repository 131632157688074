import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserFriends, FaCopy, FaEye, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

function SessionHeader({ sessionName, sessionId, players, isSpectator, onSessionNameChange, onToggleSpectatorMode, onCopy }) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [tempSessionName, setTempSessionName] = useState(sessionName);
  const [copiedItem, setCopiedItem] = useState(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setTempSessionName(sessionName);
  };

  const handleSaveClick = () => {
    onSessionNameChange({ target: { value: tempSessionName } });
    setIsEditing(false);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedItem(type);
      onCopy(type);
      setTimeout(() => setCopiedItem(null), 2000); // Reset after 2 seconds
    });
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white shadow-lg rounded-lg p-4" // Reduced padding
    >
      <div className="flex flex-wrap items-center justify-between space-y-2 sm:space-y-0">
        <div className="flex-grow flex items-center space-x-2 min-w-0">
          <span className="text-lg font-bold text-gray-800 whitespace-nowrap">{t('Session')}:</span>
          <AnimatePresence mode="wait">
            {isEditing ? (
              <motion.input
                key="input"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                type="text"
                value={tempSessionName}
                onChange={(e) => setTempSessionName(e.target.value)}
                className="text-lg font-bold text-gray-800 bg-transparent border-b-2 border-blue-500 focus:outline-none flex-grow min-w-0"
                autoFocus
              />
            ) : (
              <motion.h2
                key="heading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-lg font-bold text-gray-800 truncate flex-grow min-w-0"
              >
                {sessionName}
              </motion.h2>
            )}
          </AnimatePresence>
          <button
            onClick={isEditing ? handleSaveClick : handleEditClick}
            className="text-blue-500 hover:text-blue-600 transition-colors duration-200"
          >
            {isEditing ? <FaCheck size={16} /> : <FaPencilAlt size={16} />}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex items-center space-x-1 bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs"
          >
            <FaUserFriends size={12} />
            <span className="truncate">{players.length}</span>
          </motion.div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onToggleSpectatorMode}
            className={`px-2 py-1 rounded-full flex items-center space-x-1 transition-colors duration-200 text-xs ${
              isSpectator
                ? 'bg-blue-500 text-white'
                : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
            }`}
          >
            <FaEye size={12} />
            <span className="truncate">{isSpectator ? t('Leave') : t('Spectate')}</span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => copyToClipboard(sessionId, 'id')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded-full flex items-center justify-center transition-colors duration-200 text-xs"
          >
            <FaCopy size={12} className="mr-1" />
            <span className="truncate">{t('Copy ID')}</span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => copyToClipboard(`${window.location.origin}/session/${sessionId}`, 'link')}
            className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded-full flex items-center justify-center transition-colors duration-200 text-xs"
          >
            <FaCopy size={12} className="mr-1" />
            <span className="truncate">{t('Copy Link')}</span>
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
}

export default SessionHeader;