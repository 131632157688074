import React from 'react';
import { FaChevronDown, FaChevronUp, FaEquals } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function VotingResults({ results, isRangeEstimation }) {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  if (!results) return null;

  const resultCards = [
    { title: 'Low Average', value: results.lowAverage },
    ...(isRangeEstimation ? [{ title: 'High Average', value: results.highAverage}] : []),
    { title: 'Total Average', value: results.totalAverage },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-lg shadow-md mt-4"
    >
      <div 
        className="flex justify-between items-center mb-4 cursor-pointer" 
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3 className="text-2xl font-bold text-gray-800">{t('Voting Results')}</h3>
        {isCollapsed ? <FaChevronDown className="text-gray-500" /> : <FaChevronUp className="text-gray-500" />}
      </div>
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-2 md:grid-cols-3 gap-4"
          >
            {resultCards.map((card, index) => (
              <ResultCard key={index} title={t(card.title)} value={card.value} icon={card.icon} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

function ResultCard({ title, value, icon }) {
  return (
    <div className="bg-gray-50 p-4 rounded-lg transition-all duration-300 hover:shadow-md">
      <p className="text-sm font-medium text-gray-500 mb-1">{title}</p>
      <div className="flex items-center justify-between">
        <p className="text-3xl font-bold text-gray-800">{value}</p>
        {icon}
      </div>
    </div>
  );
}

export default VotingResults;