import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUser, FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

function JoinSessionForm({ onJoin }) {
  const [username, setUsername] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.trim()) {
      const guestId = `guest_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      onJoin(guestId, username.trim());
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md mx-auto"
    >
      <form onSubmit={handleSubmit} className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-8 rounded-lg shadow-xl">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">{t('Join Session')}</h2>
        <div className="mb-6 relative">
          <FaUser className="absolute top-3 left-3 text-white" />
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={t('Enter your name')}
            required
            className="w-full pl-10 pr-4 py-2 bg-white bg-opacity-20 border border-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-300"
          />
        </div>
        <button
          type="submit"
          className="w-full flex items-center justify-center px-4 py-2 bg-white text-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 transition-colors duration-300"
        >
          <span className="mr-2">{t('Join Session')}</span>
          <FaArrowRight />
        </button>
      </form>
    </motion.div>
  );
}

export default JoinSessionForm;