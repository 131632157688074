import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrash, FaEdit, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';

function EnhancedStoryList({ sessionId, stories, scale, onStorySelect, currentStory, onExpand }) {
  const [newStory, setNewStory] = useState('');
  const [editingStory, setEditingStory] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    onExpand(isExpanded);
  }, [isExpanded, onExpand]);

  const addStory = async () => {
    if (newStory.trim()) {
      const storyToAdd = { id: `story_${Date.now()}`, title: newStory.trim(), estimate: null };
      try {
        await updateDoc(doc(db, 'sessions', sessionId), {
          stories: arrayUnion(storyToAdd)
        });
        setNewStory('');
      } catch (error) {
        console.error('Error adding story:', error);
      }
    }
  };

  const removeStory = async (storyId) => {
    const storyToRemove = stories.find(story => story.id === storyId);
    try {
      await updateDoc(doc(db, 'sessions', sessionId), {
        stories: arrayRemove(storyToRemove)
      });
    } catch (error) {
      console.error('Error removing story:', error);
    }
  };

  const updateStory = async (storyId, newTitle) => {
    const updatedStories = stories.map(story => 
      story.id === storyId ? { ...story, title: newTitle } : story
    );
    try {
      await updateDoc(doc(db, 'sessions', sessionId), { stories: updatedStories });
      setEditingStory(null);
    } catch (error) {
      console.error('Error updating story:', error);
    }
  };

  return (
    <AnimatePresence>
      {isExpanded ? (
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: '384px', opacity: 1 }}
          exit={{ width: 0, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed top-20 right-0 bottom-0 bg-white shadow-lg z-50 flex flex-col"
          style={{ maxHeight: 'calc(100vh - 5rem)' }}
        >
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute top-4 right-4 bg-blue-500 text-white p-2 rounded-full"
          >
            <FaChevronRight className="w-4 h-4" />
          </button>
          <div className="p-4 flex flex-col h-full">
            <h2 className="text-2xl font-bold mb-4 pl-10">{t('Stories')}</h2>
            <div className="mb-4 flex">
              <input
                type="text"
                placeholder={t('Enter new story or paste Jira link')}
                value={newStory}
                onChange={(e) => setNewStory(e.target.value)}
                className="flex-grow px-4 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={addStory}
                className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition-colors"
              >
                <FaPlus />
              </button>
            </div>
            <div className="flex-grow overflow-y-auto">
              {stories.map((story) => (
                <motion.div
                  key={story.id}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className={`p-2 mb-2 rounded-md flex items-center justify-between ${
                    currentStory && currentStory.id === story.id ? 'bg-blue-100' : 'bg-gray-100'
                  }`}
                >
                  {editingStory === story.id ? (
                    <input
                      type="text"
                      value={story.title}
                      onChange={(e) => updateStory(story.id, e.target.value)}
                      onBlur={() => setEditingStory(null)}
                      className="flex-grow px-2 py-1 mr-2 border rounded"
                      autoFocus
                    />
                  ) : (
                    <span
                      className="flex-grow cursor-pointer"
                      onClick={() => onStorySelect(story)}
                    >
                      {story.title}
                    </span>
                  )}
                  <div>
                    <button
                      onClick={() => setEditingStory(story.id)}
                      className="text-blue-500 hover:text-blue-700 mr-2"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => removeStory(story.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: '48px', opacity: 1 }}
          exit={{ width: 0, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed top-20 right-0 bottom-0 bg-white shadow-lg z-50 flex flex-col items-center"
          style={{ maxHeight: 'calc(100vh - 5rem)' }}
        >
          <button
            onClick={() => setIsExpanded(true)}
            className="mt-4 bg-blue-500 text-white p-2 rounded-full"
          >
            <FaChevronLeft className="w-4 h-4" />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            <span className="transform -rotate-90 text-sm font-semibold text-gray-700 whitespace-nowrap">
              {t('Stories')}
            </span>
          </div>
          <div className="absolute bottom-4 left-0 right-0">
            <div className="flex flex-col items-center space-y-2">
              {stories.slice(0, 3).map((story, index) => (
                <div
                  key={story.id}
                  className={`w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium ${
                    currentStory && currentStory.id === story.id ? 'ring-2 ring-blue-500' : ''
                  }`}
                  onClick={() => onStorySelect(story)}
                >
                  {index + 1}
                </div>
              ))}
              {stories.length > 3 && (
                <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
                  +{stories.length - 3}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default EnhancedStoryList;
