import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUser, FaEye, FaTimes } from 'react-icons/fa';
import { AnimatedTooltip } from './Animated-tooltip';
import { doc, updateDoc, onSnapshot, arrayUnion, Timestamp, deleteField, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getDoc } from 'firebase/firestore';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const emojis = ['👍', '👎', '😊', '😂', '😍', '🎉'];

function Table({ players, isRevealed, currentPlayerId, isRangeEstimation, resetTrigger, sessionId }) {
  const [suits, setSuits] = useState({});
  const [emojiReactions, setEmojiReactions] = useState({});
  const [tempEmojis, setTempEmojis] = useState({});
  const [lastEmojiClickTime, setLastEmojiClickTime] = useState(0);
  const [showKickWarning, setShowKickWarning] = useState(null);
  const [kickedPlayerId, setKickedPlayerId] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const suitOptions = useMemo(() => ['♠', '♥', '♦', '♣'], []);

  const generateSuits = useMemo(() => {
    const newSuits = {};
    players.forEach(player => {
      if (!suits[player.id]) {
        newSuits[player.id] = {
          low: suitOptions[Math.floor(Math.random() * suitOptions.length)],
          high: suitOptions[Math.floor(Math.random() * suitOptions.length)]
        };
      } else {
        newSuits[player.id] = suits[player.id];
      }
    });
    return newSuits;
  }, [players, suitOptions]);

  useEffect(() => {
    setSuits(generateSuits);
  }, [generateSuits, resetTrigger]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'sessions', sessionId), (doc) => {
      if (doc.exists()) {
        setEmojiReactions(doc.data().emojiReactions || {});
        setTempEmojis(doc.data().tempEmojis || {});
      }
    });

    return () => unsubscribe();
  }, [sessionId]);

  useEffect(() => {
    if (isRevealed) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    }
  }, [isRevealed]);

  const handleEmojiClick = async (playerId, emoji) => {
    const currentTime = Date.now();
    if (currentTime - lastEmojiClickTime < 1000) {
      // If less than 2 seconds have passed since the last click, ignore this click
      return;
    }

    setLastEmojiClickTime(currentTime);

    const reactionRef = doc(db, 'sessions', sessionId);
    await updateDoc(reactionRef, {
      [`emojiReactions.${playerId}`]: arrayUnion({
        emoji,
        timestamp: Timestamp.now()
      }),
      [`tempEmojis.${playerId}`]: {
        emoji,
        timestamp: Timestamp.now()
      }
    });

    // Remove the temporary emoji after 2 seconds
    setTimeout(async () => {
      await updateDoc(reactionRef, {
        [`tempEmojis.${playerId}`]: deleteField()
      });
    }, 2000);
  };

  const handleKickUser = async (playerIdToKick) => {
    try {
      const playerRef = doc(db, 'sessions', sessionId, 'players', playerIdToKick);
      await deleteDoc(playerRef);
      console.log(`User ${playerIdToKick} has been kicked.`);
      setKickedPlayerId(playerIdToKick);
    } catch (error) {
      console.error("Error kicking user:", error);
    }
    setShowKickWarning(null);
  };

  const renderCard = (bet, isHighBet = false, playerId, isSpectator) => {
    const hasEstimated = bet !== null;
    const suit = suits[playerId] ? (isHighBet ? suits[playerId].high : suits[playerId].low) : '';

    return (
      <div className={`w-full h-full rounded-lg shadow-md flex items-center justify-center ${
        hasEstimated ? 'bg-blue-100' : 'bg-gray-100'
      }`}>
        <div className="relative w-full h-full flex items-center justify-center">
          {isSpectator ? (
            <FaEye className="text-xl sm:text-2xl md:text-3xl text-gray-400" />
          ) : (
            <>
              <span className={`font-bold text-base sm:text-lg md:text-xl ${isRevealed ? 'text-blue-600' : 'text-gray-400'}`}>
                {isRevealed ? (bet !== null ? bet : '-') : (hasEstimated ? '?' : '-')}
              </span>
              <span className="absolute top-1 left-1 text-xs sm:text-sm md:text-base text-gray-500">{suit}</span>
              <span className="absolute bottom-1 right-1 text-xs sm:text-sm md:text-base text-gray-500">{suit}</span>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderPlayerCard = (player) => {
    console.log('Player object:', player);
    const playerEmojis = emojiReactions[player.id] || [];
    const emojiCounts = playerEmojis.reduce((acc, r) => {
      acc[r.emoji] = (acc[r.emoji] || 0) + 1;
      return acc;
    }, {});

    const emojiContent = (
      <div className="grid grid-cols-3 gap-2 p-2">
        {emojis.map(emoji => (
          <button
            key={emoji}
            onClick={() => handleEmojiClick(player.id, emoji)}
            className="flex flex-col items-center justify-center p-1 hover:bg-gray-100 rounded-lg transition-colors duration-200"
            disabled={Date.now() - lastEmojiClickTime < 2000}
          >
            <span className="text-xl sm:text-2xl">{emoji}</span>
            <span className="text-xs">{emojiCounts[emoji] || 0}</span>
          </button>
        ))}
      </div>
    );

    return (
      <div key={player.id} className="flex flex-col items-center space-y-2 p-3 bg-white rounded-lg shadow-md w-full relative">
        <AnimatedTooltip content={emojiContent}>
          <motion.div
            className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 rounded-full overflow-hidden bg-gray-200 shadow-sm cursor-pointer relative"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {player.photoURL ? (
              console.log('Rendering image for', player.name),
              <img src={player.photoURL} alt={player.name} className="w-full h-full object-cover" />
            ) : (
              console.log('Rendering initial for', player.name),
              <div className="w-full h-full flex items-center justify-center bg-blue-500 text-white text-xl font-bold">
                {player.name.charAt(0).toUpperCase()}
              </div>
            )}
            {tempEmojis[player.id] && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30"
              >
                <span className="text-2xl sm:text-3xl md:text-4xl">{tempEmojis[player.id].emoji}</span>
              </motion.div>
            )}
          </motion.div>
        </AnimatedTooltip>
        {player.id !== currentPlayerId && (
          <button
            onClick={() => setShowKickWarning(player.id)}
            className="absolute top-1 right-1 text-red-500 hover:text-red-700 transition-colors duration-200"
          >
            <FaTimes />
          </button>
        )}
        <span className="text-sm sm:text-base md:text-lg font-medium text-gray-700 truncate w-full text-center">
          {player.name}
        </span>
        <div className="flex justify-center space-x-2 w-full">
          <div className="w-10 h-14 sm:w-12 sm:h-16 md:w-14 md:h-20">
            {renderCard(player.lowBet, false, player.id, player.isSpectator)}
          </div>
          {isRangeEstimation && !player.isSpectator && (
            <div className="w-10 h-14 sm:w-12 sm:h-16 md:w-14 md:h-20">
              {renderCard(player.highBet, true, player.id, player.isSpectator)}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full overflow-x-auto">
      {showConfetti && <Confetti width={width} height={height} recycle={false} numberOfPieces={200} />}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-4 md:gap-5">
        {players.map(renderPlayerCard)}
      </div>
      {showKickWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <p className="mb-4">
              Are you sure you want to kick {players.find(p => p.id === showKickWarning)?.name || 'this player'}?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowKickWarning(null)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={() => handleKickUser(showKickWarning)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Kick
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Table;
